.datePickerRestyle {
    border: 1px rgb(195, 194, 194) solid !important;
    border-radius: 5px !important;
    color: black !important;
}

.datePickerRestyleV2 {
    border: 1px rgb(195, 194, 194) solid !important;
    border-radius: 5px !important;
    color: black !important;
}

.react-datetime-picker__calendar react-datetime-picker__calendar--open{
    z-index: 9999999999999999999 !important;
}

.react-date-picker__wrapper {
    border: none !important;
    background-color: aqua;
    z-index: 99999999999999 !important;
}