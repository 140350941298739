@import "~react-image-gallery/styles/css/image-gallery.css";
* {
    /* For Firefox */
    scrollbar-width: thin;
}

/* For Chrome and other browsers except Firefox */
body::-webkit-scrollbar {
    width: 0.5em;
    height: 0.5em;
}
