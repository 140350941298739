.opciones {
    display: flex;
  }
  
  .boton {
    flex: 1;
    margin: 0.5rem;
    padding: 1rem;
    font-size: 1rem;
    border-radius: 0.25rem;
  }
  
  .seleccionado {
    border-color: yellow;
    background-color: rgba(0, 128, 0, 0.5);
  }
  
  .boton.seleccionado {
    border-color: yellow;
    background-color: rgba(0, 128, 0, 0.5);
  }
  
  .opciones {
    display: flex;
    flex-wrap: wrap;
  }
  .opciones.multilinea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  .boton {
    width: 250px;
    margin-right: 10px;
  }